.contentWrapper {
  max-width: 960px;
  min-width: 758px;
  margin: auto;
  color: #fffaf0;
}
@media screen and (max-width:758px) {
  .contentWrapper {
    min-width: 100px;
  }
}

html {
  background-image: url("./images/sitebg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #177245;
}

h1 {
  position: absolute;
}

::-webkit-scrollbar {
  height: 0.5rem;
  width: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc8c0;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #666460; 

}

::-webkit-scrollbar-track {
  background-color: #999690; 
}
