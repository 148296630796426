h2 {
    text-align: center;
}
.imageWrapper {
    display:flex;
    margin-bottom: 2rem;
}

img {
    width: 50%;
    margin-right: 2rem;
}

.pagesBorder {
    background-color: #fffaf0;
    border-radius: 0.4rem;
    padding: 0.25rem;
    width: fit-content;
    margin: auto;
}

.pagesWrapper {
    background-color: #177245;
    border-radius: 0.2rem;
    padding: 0.25rem 1.75rem 0.75rem ;
    overflow-y: overlay;
    height: fit-content;
    max-height: 78vh;
}
